var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      staticClass: "portion-svg",
      attrs: {
        width: "24",
        height: "21",
        viewBox: "0 0 24 21",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M6.01114 16.1439C5.50165 16.1439 5.08863 15.7308 5.08863 15.2213V1.38376C5.08863 0.874273 5.50165 0.461253 6.01114 0.461253C6.52062 0.461253 6.93364 0.874273 6.93364 1.38376V15.2213C6.93364 15.7308 6.52062 16.1439 6.01114 16.1439Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          d: "M18.4645 13.3763H0.936905L5.54943 8.76381H23.077L18.4645 13.3763Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M4.89712 8.1115C5.07013 7.93849 5.30477 7.8413 5.54943 7.8413H23.077C23.4502 7.8413 23.7865 8.06606 23.9293 8.41078C24.0721 8.75549 23.9932 9.15228 23.7294 9.41611L19.1168 14.0286C18.9438 14.2016 18.7092 14.2988 18.4645 14.2988H0.936906C0.563787 14.2988 0.227408 14.0741 0.0846213 13.7294C-0.0581651 13.3846 0.0207606 12.9879 0.284595 12.724L4.89712 8.1115ZM5.93155 9.68631L3.16403 12.4538H18.0824L20.8499 9.68631H5.93155Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M0.922506 20.7563C0.41302 20.7563 6.6713e-08 20.3433 1.34716e-08 19.8338L0 5.62154C0 5.11205 0.41302 4.69903 0.922506 4.69903C1.43199 4.69903 1.84501 5.11205 1.84501 5.62154V19.8338C1.84501 20.3433 1.43199 20.7563 0.922506 20.7563Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M18.0033 20.7564C17.4938 20.7564 17.0808 20.3434 17.0808 19.8339V5.99629C17.0808 5.4868 17.4938 5.07378 18.0033 5.07378C18.5127 5.07378 18.9258 5.4868 18.9258 5.99629V19.8339C18.9258 20.3434 18.5127 20.7564 18.0033 20.7564Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M23.0775 16.1439C22.568 16.1439 22.155 15.7308 22.155 15.2213V1.38376C22.155 0.874272 22.568 0.461253 23.0775 0.461253C23.587 0.461253 24 0.874272 24 1.38376V15.2213C24 15.7308 23.587 16.1439 23.0775 16.1439Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          d: "M18.4645 5.53503H0.936905L5.54943 0.922506H23.077L18.4645 5.53503Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M4.89712 0.270196C5.07013 0.0971923 5.30477 0 5.54943 0H23.077C23.4502 0 23.7865 0.224761 23.9293 0.569478C24.0721 0.914195 23.9932 1.31098 23.7294 1.57482L19.1168 6.18734C18.9438 6.36035 18.7092 6.45754 18.4645 6.45754H0.936906C0.563787 6.45754 0.227408 6.23278 0.0846213 5.88806C-0.0581651 5.54335 0.0207606 5.14656 0.284595 4.88272L4.89712 0.270196ZM5.93155 1.84501L3.16403 4.61253H18.0824L20.8499 1.84501H5.93155Z",
          fill: _vm.color,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }