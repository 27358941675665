
import {
  modelViewerStore,
  removeSelection,
  setIsolation,
  setIsolationEnabled,
} from '@/store/modelViewer.store'
import { CsFlex, CsTreeview } from '@consteel/csuetify'
import { Model } from '@consteel/stoke/src/ObjectDataBuilders/SmadsteelObjectBuilder'
import { CacheContainer, GetAll, PortionFolder } from '@consteel/storm'
import { guid } from '@consteel/storm/src/Models/ModelBase'
import Vue from 'vue'

type PortionNode = {
  name: string
  id: guid
  objectItems?: guid[]
  disabled?: boolean
  children?: PortionNode[]
}

export default Vue.extend({
  name: 'PortionTab',
  components: {
    CsFlex,
    CsTreeview,
  },
  data() {
    return {
      cache: new CacheContainer(),
      selected: [] as Array<PortionNode>,
    }
  },
  computed: {
    isolationEnabled(): boolean {
      return modelViewerStore.isolationEnabled
    },
    model(): Model | null | undefined {
      return modelViewerStore.model.rawSmadsteelModel
    },
    portions(): Array<PortionNode> {
      if (!modelViewerStore.model.rawSmadsteelModel) return []

      const portionFolders = GetAll<PortionFolder>(
        modelViewerStore.model.rawSmadsteelModel,
        PortionFolder,
        undefined,
        this.cache
      ).filter((portionFolder) => portionFolder.isRoot)

      return this.buildPortionTree(portionFolders)
    },
  },

  watch: {
    model() {
      setIsolationEnabled(false, true)
      setIsolation([])
    },
  },
  methods: {
    buildPortionTree(portionFolders: PortionFolder[]): PortionNode[] {
      return portionFolders.map((portionFolder): PortionNode => {
        return {
          name: portionFolder.name || '',
          id: portionFolder.id || '',
          disabled: !this.isolationEnabled,
          children: [
            ...this.buildPortionTree(portionFolder.portionFolders),
            ...portionFolder.portions.map((portion) => ({
              name: portion.name || '',
              id: portion.id || '',
              children: [],
              disabled: !this.isolationEnabled,
              objectItems: portion.items?.map((item) => item.InstanceGuid) || [],
            })),
          ],
        }
      })
    },
    handleTogglePortions(event: any): void {
      removeSelection()
      const value = event.target.checked
      setIsolationEnabled(value)
    },
    handleChangeSelectedPortion(newVal: PortionNode[]): void {
      removeSelection()
      const isolatedModelObjectIds = [] as string[]
      newVal.forEach((selectedPortion) => {
        if (selectedPortion && selectedPortion.objectItems)
          isolatedModelObjectIds.push(...selectedPortion.objectItems)
      })
      setIsolation(isolatedModelObjectIds)
    },
  },
})
