
import Vue from 'vue'

export default Vue.extend({
  name: 'SupplySvgVue',
  props: {
    color: {
      required: false,
      default: 'currentColor',
      type: String,
    },
    scale: {
      required: false,
      default: 1,
      type: Number,
    },
  },
})
