import { render, staticRenderFns } from "./PortionTab.vue?vue&type=template&id=cdb2f62a&scoped=true"
import script from "./PortionTab.vue?vue&type=script&lang=ts"
export * from "./PortionTab.vue?vue&type=script&lang=ts"
import style0 from "./PortionTab.vue?vue&type=style&index=0&id=cdb2f62a&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdb2f62a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cdb2f62a')) {
      api.createRecord('cdb2f62a', component.options)
    } else {
      api.reload('cdb2f62a', component.options)
    }
    module.hot.accept("./PortionTab.vue?vue&type=template&id=cdb2f62a&scoped=true", function () {
      api.rerender('cdb2f62a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Viewer/PortionTab.vue"
export default component.exports