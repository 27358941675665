var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "portion-tab pa-5",
      attrs: { "fill-height": "", "fill-width": "", vertical: "" },
    },
    [
      _c(
        "div",
        { staticClass: "mr-auto mb-3" },
        [
          _c("input", {
            attrs: { type: "checkbox" },
            domProps: { checked: _vm.isolationEnabled },
            on: { change: _vm.handleTogglePortions },
          }),
          _c("span", { staticClass: "pb-2 ml-3" }, [
            _vm._v(_vm._s(_vm.$t("Portions & Storeys"))),
          ]),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "", "max-width": "350px" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-icon",
                        _vm._g(
                          _vm._b(
                            { staticClass: "ml-2", attrs: { size: "16" } },
                            "v-icon",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v("mdi-information-outline")]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "Listed portions and storeys imported from other structural design and analysis software."
                    )
                  )
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("cs-treeview", {
        staticClass: "portion-tree",
        attrs: {
          selectable: "",
          items: _vm.portions,
          "selected-color": "primary",
          "return-object": "",
        },
        on: {
          input: function ($event) {
            return _vm.handleChangeSelectedPortion($event)
          },
        },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }