import { render, staticRenderFns } from "./SupplyOptimizationDialog.vue?vue&type=template&id=679e554b&scoped=true"
import script from "./SupplyOptimizationDialog.vue?vue&type=script&lang=ts"
export * from "./SupplyOptimizationDialog.vue?vue&type=script&lang=ts"
import style0 from "./SupplyOptimizationDialog.vue?vue&type=style&index=0&id=679e554b&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "679e554b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('679e554b')) {
      api.createRecord('679e554b', component.options)
    } else {
      api.reload('679e554b', component.options)
    }
    module.hot.accept("./SupplyOptimizationDialog.vue?vue&type=template&id=679e554b&scoped=true", function () {
      api.rerender('679e554b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Dialogs/SupplyOptimizationDialog/SupplyOptimizationDialog.vue"
export default component.exports