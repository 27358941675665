
import { CsFlex } from '@consteel/csuetify'
import Vue from 'vue'
import SupplySvg from '../../assets/viewer/Supply.svg.vue'

export default Vue.extend({
  name: 'EmptySupply',
  components: {
    CsFlex,
    SupplySvg,
  },
  props: {
    iconSize: {
      type: Number,
      default: 60,
    },
  },
})
