var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    { staticClass: "empty-supply", attrs: { justify: "center", vertical: "" } },
    [
      _c(
        "supply-svg",
        {
          staticClass: "mb-9 empty-supply-svg",
          attrs: { scale: 3, size: _vm.iconSize },
        },
        [_vm._v("mdi-sync")]
      ),
      _c("div", { staticClass: "empty-supply-text mt-3" }, [
        _c("div", [
          _vm._v(
            " " +
              _vm._s(_vm.$t("Még nem indítottál beszerzési folyamatot.")) +
              " "
          ),
        ]),
        _c("div", [
          _vm._v(" " + _vm._s(_vm.$t(" Kattints az Indítás gombra!")) + " "),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }